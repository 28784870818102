export const useCity = () => {
    const city = useState("city", () => "Sydney")
    const loadedCity = useState("loaded-city", () => false)

    onMounted(() => {
        if (loadedCity.value) {
            return
        }
        loadedCity.value = true

        let c = localStorage.getItem("user-city")
        if (c) {
            city.value = c
        }
    })

    watch(city, (v) => {
        localStorage.setItem("user-city", v)
    })

    return city
}
